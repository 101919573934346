export function getBackendUrl(): string {
    console.log(process.env.REACT_APP_BACKEND_URL!);

    return process.env.REACT_APP_BACKEND_URL!;
}

export default async function fetchData(input: RequestInfo, init?: RequestInit) {
    const response = await fetch(input, init);
    if (response.ok) {
        return response;
    } else {
        const errorBody = await response.json();
        const errorMessage = errorBody.error;

        throw Error(errorMessage);
    }
}
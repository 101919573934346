import {User} from "../models/user";
import fetchData, {getBackendUrl} from "./fetchData";

export interface LoginInput {
    username: string,
    password: string
}

export async function login(input: LoginInput): Promise<User> {
    const response = await fetchData(getBackendUrl() + "/api/users/login", {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(input)
    });

    return response.json();
}

export async function getLoggedInUser(): Promise<User> {
    const response = await fetchData(getBackendUrl() + "/api/users", {
        method: "GET",
        credentials: "include"
    });
    return response.json();
}

export async function logout() {
    await fetchData(getBackendUrl() + "/api/users/logout", {
        method: "POST",
        credentials: "include"
    });
}
import {Transaction} from "../models/transaction";
import fetchData, {getBackendUrl} from "./fetchData";

export async function getTransactionsToday(): Promise<Transaction[]> {
    const response = await fetchData( getBackendUrl() + "/api/transactions", {
        method: "GET",
        credentials: "include"
    });
    return response.json();
}

export async function getTransactionsByDate(date: string): Promise<Transaction[]> {
    const response = await fetchData(getBackendUrl() + "/api/transactions/date/" + date, {
        method: "GET",
        credentials: "include"
    });
    return response.json();
}

export async function getTransactionsByMonth(monthIndex: string): Promise<Transaction[]> {
    const response = await fetchData(getBackendUrl() + "/api/transactions/month/" + monthIndex, {
        method: "GET",
        credentials: "include"
    });
    return response.json();
}

export interface FilterTransactionsByRangeInput {
    startDate: string,
    endDate: string
}

export async function getTransactionsByRange(input: FilterTransactionsByRangeInput): Promise<Transaction[]> {
    const response = await fetchData(getBackendUrl() + "/api/transactions/range", {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(input)
    });
    return response.json();
}

export interface CreateTransactionInput {
    customerFirstName: string,
    customerLastName: string,
    customerContactNo: string,
    amount: number
}

export async function createTransaction(input: CreateTransactionInput): Promise<Transaction> {
    const response = await fetchData(getBackendUrl() + "/api/transactions", {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(input)
    });
    return response.json();
}
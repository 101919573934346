import {User} from "../models/user";
import {Button, Navbar} from "react-bootstrap";
import * as UsersApi from "../network/users_api";

interface NavbarLoggedInViewProps {
    user: User,
    onLogoutSuccessful: () => void
}

const NavbarLoggedInView = ({user, onLogoutSuccessful}: NavbarLoggedInViewProps) => {

    async function logout() {
        try {
            await UsersApi.logout();
            onLogoutSuccessful();
        } catch (error) {
            alert(error);
        }
    }

    return (
        <>
            <Navbar.Text className="me-2">
                username: {user.username}
            </Navbar.Text>
            <Button onClick={logout}>
                Logout
            </Button>
        </>
    );
}

export default NavbarLoggedInView;
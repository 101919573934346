import {Alert, Button, Card, Form, Modal} from "react-bootstrap";
import styles from "../styles/LoginPage.module.css";
import utilStyles from "../styles/utils.module.css";
import {useForm} from "react-hook-form";
import {login, LoginInput} from "../network/users_api";
import {useState} from "react";
import {User} from "../models/user";

interface LoginPageProps {
    onLoginSuccessful: (user: User) => void
}

const LoginPage = ({onLoginSuccessful}: LoginPageProps) => {

    const [error, setError] = useState<string | null>(null);
    const [showContactAdminDialog, setShowContactAdminDialog] = useState(false);

    const {
        register,
        handleSubmit,
        formState: {errors, isSubmitting}
    } = useForm<LoginInput>();

    async function onSubmit(input: LoginInput) {
        try {
            const user = await login(input);
            console.log(user);

            onLoginSuccessful(user);
        } catch (error) {
            if (error instanceof Error) {
                setError(error.message);
            }
        }
    }

    return (
        <>
            <Form className={`${styles.loginFormCenter}`} onSubmit={handleSubmit(onSubmit)}>
                <Card className={utilStyles.width100}>
                    <Card.Body>
                        {error &&
                            <Alert variant="danger" className={utilStyles.width100}>
                                {error}
                            </Alert>
                        }

                        <Card.Title className={`mb-4 ${utilStyles.flexCenter}`}>Welcome to Floating
                            Cottages</Card.Title>

                        <Form.Group className={`mb-3 ${utilStyles.width100}`}>
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Username"
                                isInvalid={!!errors.username}
                                {...register("username", {required: "Required"})}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.username?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className={`${utilStyles.width100}`}>
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                isInvalid={!!errors.password}
                                {...register("password", {required: "Required"})}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.password?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Button variant="link" className={`${utilStyles.blockEnd} ms-auto mb-3`}
                                onClick={() => setShowContactAdminDialog(true)}>Forgot
                            Password?</Button>

                        <Button type="submit" className={utilStyles.width100} disabled={isSubmitting}>Login</Button>
                    </Card.Body>
                </Card>
            </Form>

            {showContactAdminDialog &&
                <Modal show onHide={() => setShowContactAdminDialog(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Forgot Password
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        Please contact your admin to change/retrieve your password.
                    </Modal.Body>
                </Modal>
            }
        </>
    );
}

export default LoginPage;
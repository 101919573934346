import {Alert, Button, Card, Col, Form, Row, Table} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import {Transaction} from "../models/transaction";
import {formatCurrency} from "../utils/formatCurrency";
import utilStyles from "../styles/utils.module.css";
import {formatDateShort} from "../utils/formatDate";
import {User} from "../models/user";
import {MdArrowDropDown, MdArrowDropUp} from "react-icons/md";
import {useNavigate} from "react-router-dom";
import * as TransactionsApi from "../network/transactions_api";

interface TransactionFilterInput {
    dateSelected: string | null,
    monthSelected: string | null,
    startDateText: string | null,
    endDateText: string | null
}

const sampleTransactions: Transaction[] = [
    {
        _id: "6466f5a39a61ef9c83b7b84a",
        customerFirstName: "John",
        customerLastName: "Doe 1",
        customerContactNo: "09123456789",
        amount: 5000,
        createdAt: "2023-05-22"
    },
    {
        _id: "6466f5a39a61ef9c83b7b84b",
        customerFirstName: "John",
        customerLastName: "Doe 2",
        customerContactNo: "09223456789",
        amount: 5000,
        createdAt: "2023-05-22"
    },
    {
        _id: "6466f5a39a61ef9c83b7b84c",
        customerFirstName: "John",
        customerLastName: "Doe 3",
        customerContactNo: "09323456789",
        amount: 5000,
        createdAt: "2023-05-22"
    },
    {
        _id: "6466f5a39a61ef9c83b7b84d",
        customerFirstName: "John",
        customerLastName: "Doe 4",
        customerContactNo: "09423456789",
        amount: 5000,
        createdAt: "2023-05-21"
    },
    {
        _id: "6466f5a39a61ef9c83b7b84e",
        customerFirstName: "John",
        customerLastName: "Doe 5",
        customerContactNo: "09523456789",
        amount: 5000,
        createdAt: "2023-05-21"
    }
]

const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
]

interface TransactionsPageProps {
    user: User | null
}

const TransactionsPage = ({user}: TransactionsPageProps) => {

    const navigator = useNavigate();

    const {
        register,
        handleSubmit,
        formState: {isSubmitting}
    } = useForm<TransactionFilterInput>();

    const [title, setTitle] = useState("");
    const [transactions, setTransactions] = useState<Transaction[]>([]);

    const [error, setError] = useState<string | null>(null);
    const [showFilters, setShowFilters] = useState(false);

    useEffect(() => {
        async function fetchTransactions() {
            try {
                const dateNow = new Date().toLocaleDateString();

                const transactionsToday = await TransactionsApi.getTransactionsToday();
                setTransactions(transactionsToday);
                setTitle(`(for ${dateNow})`);
            } catch (error) {
                if (error instanceof Error) {
                    setErrorMessage(error.message);
                }
            }
        }

        fetchTransactions();
    }, []);


    const total = transactions.map(transaction => transaction.amount).reduce((sum, i) => sum + i, 0);
    const serviceFeeTotal = total * 0.05;
    const netTotal = total - serviceFeeTotal;

    async function onFilterByDate(input: TransactionFilterInput) {
        if (!input.dateSelected) {
            setErrorMessage("No date selected.");
            return;
        }

        const date = new Date(input.dateSelected).toLocaleDateString().split("/").join("-");

        try {
            const transactionsByDate = await TransactionsApi.getTransactionsByDate(date);
            setTransactions(transactionsByDate);
            setTitle(`(for ${date})`);
        } catch (error) {
            if (error instanceof Error) {
                setErrorMessage(error.message);
            }
        }
    }

    async function onFilterByMonth(input: TransactionFilterInput) {
        if (!input.monthSelected) {
            setErrorMessage("No month selected.");
            return;
        }

        if (input.monthSelected === "-1") {
            setErrorMessage("No month selected.");
            return;
        }

        try {
            const transactionsByMonth = await TransactionsApi.getTransactionsByMonth(input.monthSelected);
            setTransactions(transactionsByMonth);
            setTitle(`(for month of ${months[parseInt(input.monthSelected)]})`);
        } catch (error) {
            if (error instanceof Error) {
                setErrorMessage(error.message);
            }
        }
    }

    async function onFilterByDateRange(input: TransactionFilterInput) {
        if (!input.startDateText || !input.endDateText) {
            setErrorMessage("Please select a start and end date.");
            return;
        }

        const startDate = new Date(input.startDateText);
        const endDate = new Date(input.endDateText);

        if (startDate > endDate) {
            setErrorMessage("End date should be greater than start date.");
            return;
        }

        try {
            const transactionsByDateRange = await TransactionsApi.getTransactionsByRange({
                startDate: startDate.toLocaleDateString().split("/").join("-"),
                endDate: endDate.toLocaleDateString().split("/").join("-")
            });
            setTransactions(transactionsByDateRange);
            setTitle(`(from ${startDate.toLocaleDateString()} to ${endDate.toLocaleDateString()})`);
        } catch (error) {
            if (error instanceof Error) {
                setErrorMessage(error.message);
            }
        }
    }

    function setErrorMessage(message: string) {
        setError(message);

        setTimeout(() => {
            setError(null);
        }, 5000)
    }

    return (
        <>
            {user
                ? <>
                    {error &&
                        <Alert variant="danger">
                            {error}
                        </Alert>
                    }

                    <div className={`${utilStyles.flexStart} mb-4`}>
                        <Button onClick={() => {
                            navigator("/create-transaction");
                        }}>Create Transaction</Button>
                    </div>

                    <Card className={"mb-4"}>
                        <Card.Header>
                            <Card.Title>
                                Transactions {title}
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            Gross Total: <b>{formatCurrency(total)}</b><br/>
                            Service Fee Total: <b>{formatCurrency(serviceFeeTotal)}</b><br/>
                            Net Total: <b>{formatCurrency(netTotal)}</b>
                        </Card.Body>
                    </Card>

                    <Card className="mb-4">
                        <Card.Header>
                            <Card.Title className={utilStyles.flexCenter}>
                                Filters

                                {showFilters
                                    ? <MdArrowDropDown
                                        className={"ms-auto"}
                                        onClick={(e) => {
                                            setShowFilters(false);
                                            e.stopPropagation();
                                        }}
                                    />
                                    : <MdArrowDropUp
                                        className={"ms-auto"}
                                        onClick={(e) => {
                                            setShowFilters(true);
                                            e.stopPropagation();
                                        }}
                                    />
                                }
                            </Card.Title>

                        </Card.Header>

                        {showFilters &&
                            <Card.Body>
                                <Form className="mb-4" onSubmit={handleSubmit(onFilterByDate)}>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Filter By Date</Form.Label>
                                        <Form.Control
                                            type="date"
                                            {...register("dateSelected")}
                                        />
                                    </Form.Group>

                                    <Button type="submit" disabled={isSubmitting}
                                            className={`${utilStyles.blockEnd}`}>Filter</Button>
                                </Form>

                                <Form className="mb-4" onSubmit={handleSubmit(onFilterByMonth)}>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Filter By Month</Form.Label>
                                        <Form.Select
                                            {...register("monthSelected")}
                                        >
                                            <option value="-1"></option>
                                            {months.map((item, i) => <option value={i}>{item}</option>)}
                                        </Form.Select>
                                    </Form.Group>

                                    <Button type="submit" disabled={isSubmitting}
                                            className={`${utilStyles.blockEnd}`}>Filter</Button>
                                </Form>

                                <Form onSubmit={handleSubmit(onFilterByDateRange)}>
                                    <Row className="mb-2">
                                        <Form.Group as={Col} controlId="startdate-input">
                                            <Form.Label>Start Date</Form.Label>
                                            <Form.Control
                                                type="date"
                                                {...register("startDateText")}
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="enddate-input">
                                            <Form.Label>End Date</Form.Label>
                                            <Form.Control
                                                type="date"
                                                {...register("endDateText")}
                                            />
                                        </Form.Group>
                                    </Row>

                                    <Button type="submit" disabled={isSubmitting}
                                            className={`${utilStyles.blockEnd}`}>Filter</Button>
                                </Form>
                            </Card.Body>
                        }
                    </Card>


                    <Table responsive bordered hover>
                        <thead className="bg-primary">
                        <tr className="text-light">
                            <th>ID</th>
                            <th>Customer Name</th>
                            <th>Customer Contact Number</th>
                            <th>Amount</th>
                            <th>Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        {transactions.map(transaction => {
                            return <tr key={transaction._id}>
                                <td>{transaction._id}</td>
                                <td>{transaction.customerFirstName} {transaction.customerLastName}</td>
                                <td>{transaction.customerContactNo}</td>
                                <td>{formatCurrency(transaction.amount)}</td>
                                <td>{formatDateShort(transaction.createdAt)}</td>
                            </tr>
                        })}
                        </tbody>
                    </Table>
                </>
                : <p>Please log in to see your transactions.</p>
            }
        </>
    );
}

export default TransactionsPage;
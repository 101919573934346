import {User} from "../models/user";
import {Alert, Button, Card, Form} from "react-bootstrap";
import * as TransactionsApi from "../network/transactions_api";
import {CreateTransactionInput} from "../network/transactions_api";
import {useForm} from "react-hook-form";
import utilStyles from "../styles/utils.module.css";
import styles from "../styles/CreateTransactionPage.module.css";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

interface CreateTransactionPageProps {
    user: User|null
}

const CreateTransactionPage = ({user}: CreateTransactionPageProps) => {

    const navigator = useNavigate();

    const {
        register,
        handleSubmit,
        formState: {errors, isSubmitting}
    } = useForm<CreateTransactionInput>({
        defaultValues: {
            amount: 5000
        }
    });

    const [error, setError] = useState<string|null>(null);

    async function onSubmit(input: CreateTransactionInput) {
        try {
            await TransactionsApi.createTransaction(input);
            navigator("/home");
        } catch (error) {
            if (error instanceof Error) {
                setError(error.message);
            }
        }
    }

    return (
        <>
            {user
                ? <div className={styles.createTransactionFormCenter}>
                    <h3 className="mb-4">Create Transaction</h3>
                    <Card className={utilStyles.width100}>
                        <Card.Body>
                            { error &&
                                <Alert variant="danger">
                                    {error}
                                </Alert>
                            }

                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Form.Group className="mb-4">
                                    <Form.Label>Customer First Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Customer First Name"
                                        isInvalid={!!errors.customerFirstName}
                                        {...register("customerFirstName", {required: "Required"})}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.customerFirstName?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-4">
                                    <Form.Label>Customer Last Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Customer Last Name"
                                        isInvalid={!!errors.customerLastName}
                                        {...register("customerLastName", {required: "Required"})}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.customerLastName?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-4">
                                    <Form.Label>Customer Contact No.</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Customer Contact No."
                                        isInvalid={!!errors.customerContactNo}
                                        {...register("customerContactNo", {required: "Required"})}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.customerContactNo?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-4">
                                    <Form.Label>Amount</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Amount"
                                        isInvalid={!!errors.amount}
                                        {...register("amount", {required: "Required"})}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.amount?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Button type="submit" className={utilStyles.width100} disabled={isSubmitting}>Submit</Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
                : <p>Please log in to create transaction.</p>
            }
        </>
    );
}

export default CreateTransactionPage;
import React, {useEffect, useState} from 'react';
import {Container} from "react-bootstrap";
import {Route, Routes, useNavigate} from "react-router-dom";
import {User} from "./models/user";
import TransactionsPage from "./pages/TransactionsPage";
import CreateTransactionPage from "./pages/CreateTransactionPage";
import utilStyles from "./styles/utils.module.css";
import MyNavBar from "./components/MyNavBar";
import {getLoggedInUser} from "./network/users_api";
import LoginPage from "./pages/LoginPage";
import AboutPage from "./pages/AboutPage";

function App() {

    const navigator = useNavigate();
    const [loggedInUser, setLoggedInUser] = useState<User | null>(null);

    useEffect(() => {
        async function fetchLoggedInUser() {
            try {
                const user = await getLoggedInUser();
                setLoggedInUser(user);
                navigator("/home");
            } catch (error) {
                console.log(error);
            }
        }

        fetchLoggedInUser();
    }, []);

    return (
        <>
            <MyNavBar loggedInUser={loggedInUser} onLogoutSuccessful={() => {
                setLoggedInUser(null);
                navigator("/");
            }}/>

            <Container className={utilStyles.paddingVertical32}>
                <Routes>
                    <Route
                        path="/"
                        element={<LoginPage onLoginSuccessful={(user => {
                            setLoggedInUser(user);
                            navigator("/home");
                        })}/>}
                    />
                    <Route
                        path="/home"
                        element={<TransactionsPage user={loggedInUser}/>}
                    />
                    <Route
                        path="/create-transaction"
                        element={<CreateTransactionPage user={loggedInUser}/>}
                    />
                    <Route
                        path="/about"
                        element={<AboutPage/>}
                    />
                </Routes>
            </Container>
        </>
    );
}

export default App;

import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";

const NavbarLoggedOutView = () => {
    return (
        <>
            <Link to="/">
                <Button>Login</Button>
            </Link>
        </>
    );
}

export default NavbarLoggedOutView;